function submitFlexibleContactForm() {
    const forms = Array.from(document.querySelectorAll('.FlexibleContactForm-form'));

    if (forms && forms.length) {

        forms.forEach((form) => {
            form.addEventListener('submit', function(e) {
                e.preventDefault()
                const formData = new FormData(form);
                const formDataObject = {};
                formData.forEach((value, key) => {formDataObject[key] = value});
                const jsonData = JSON.stringify(formDataObject);
                const request = $.ajax({
                             url: form.getAttribute('data-action'),
                             type: 'POST',
                             contentType: 'application/json; charset=utf-8',
                             data: jsonData
                 });
                 request.done(function (data) {
                     form.reset()
                     const submitBtn = form.querySelector(
                        'input[type="submit"]'
                        )
                    submitBtn.disabled = true
                    const successMsg = form.querySelector('.finished')
                    successMsg.style.display = 'block'
                 });

                 request.fail(function (jqXHR) {
                     console.log(jqXHR.responseText);
                 })
            })
        })
    }
}

export default submitFlexibleContactForm