$(function() {
	
	$( ".js-visionslider-next" ).click(function() {
		if($('.vision-slider').hasClass('loading')){
			return false;
		}
		var $ch = $('.vision-slider').children();
		var $cu = $ch.filter('.vision-slider__slide--active');
		var $n = $cu.next();

		if(!$n.length){
			$n = $ch.first();
		}

		$('.vision-slider').addClass('loading');

		slideOut($cu, 0, function(){
			slideIn($n, 1);
		});


		updateSliderNav($n);
		updateBgColor($n);

	});

	$( ".js-visionslider-prev" ).click(function() {
		if($('.vision-slider').hasClass('loading')){
			return false;
		}

		var $ch = $('.vision-slider').children();
		var $cu = $ch.filter('.vision-slider__slide--active');
		var $n = $cu.prev(); 

		if(!$n.length){
			$n = $ch.last();
		}

		$('.vision-slider').addClass('loading');

		slideOut($cu, 1, function(){
			slideIn($n, 0);
		});

		updateSliderNav($n);
		updateBgColor($n);
	});

	$('.vision-slider__control').click(function() {

		if($('.vision-slider').hasClass('loading')){
			return false;
		}

		var $el = $(this);

		if(!$el.hasClass('vision-slider__control--active')){
			var ci = $('.vision-slider__control--active--active').data('num');
			var ni = $el.data('num');

			var $ch = $('.vision-slider').children();
			var $cu = $ch.filter('.vision-slider__slide--active');
			var $n = $ch.eq(ni); 

			if(!$n.length){
				$n = $ch.first();
			}

			$('.vision-slider').addClass('loading');

			if(ci > ni){
				slideOut($cu, 1, function(){
					slideIn($n, 0);
				});
			} else {
				slideOut($cu, 0, function(){
					slideIn($n, 1);
				});
			}

			updateSliderNav($n);
			updateBgColor($n);
		}
		
	});


	function slideIn($slide, direction) {
		$slide.addClass('noanim');

		if(direction == 0){

			$slide.addClass('vision-slider__slide--fadeout');
			$slide.removeClass('noanim');

			$slide.addClass('vision-slider__slide--active');

			setTimeout(function(){
				$slide.removeClass('vision-slider__slide--fadeout');
			}, 100 );
		
		}else if(direction == 1) {

			$slide.addClass('vision-slider__slide--fadein');
			$slide.removeClass('noanim');

			$slide.addClass('vision-slider__slide--active');

			setTimeout(function(){
				$slide.removeClass('vision-slider__slide--fadein');
			}, 100 );
			
		}

		setTimeout(function(){
			$('.vision-slider').removeClass('loading');
		}, 100);
		
	}

	function slideOut($slide, direction, callback) {
		if(direction == 0){
			$slide.addClass('vision-slider__slide--fadeout');
		} else if (direction == 1){
			$slide.addClass('vision-slider__slide--fadein');
		}

		setTimeout(function(){

			$slide.removeClass('vision-slider__slide--active');
			$slide.removeClass('vision-slider__slide--fadeout');
			$slide.removeClass('vision-slider__slide--fadein');

			callback();
		}, 100);
	}

	function updateSliderNav($n){
		var index = $n.index();
		var control = $('.vision-slider__control');

		control.removeClass('vision-slider__control--active');

		control.filter('[data-num="'+index+'"]').addClass('vision-slider__control--active');

	}

	function updateBgColor($n){
		var index = $n.index();
		var colors = ['darkblue', 'blue', 'green'];
		var bg = $('.vision-slider__slide__content');
		var nav = $('.vision-slider__controls');

		setTimeout(function(){
			$.each(colors, function($c){
				var classname = 'vision-slider__slide__content--'+colors[$c];
				var navclassname = 'vision-slider__controls--'+colors[$c];
				bg.removeClass(classname);
				nav.removeClass(navclassname);
			});

			var classname = 'vision-slider__slide__content--'+colors[index];
			var navclassname = 'vision-slider__controls--'+colors[index];

			bg.addClass(classname);
			nav.addClass(navclassname);
		}, 10);
	}


});