import Typed from 'typed.js';

$('body').on('vue-loaded', function() {

	/*
	*   Job application
	*/

	//This prints sentences in a human way.
	var botTyped = function(selector){
		if($(selector).length > 0 ){
			var typed = new Typed(selector + ' .typed', {
				stringsElement: selector + ' .text',
				typeSpeed: 20,
				showCursor: false
			});
			return typed;
		}
		else
		{
			console.log('not found');
		}
	};

	var textTyped = { destroy:function(){

	}};

	if($('#jobApplicationWizard').length){
		$('#jobApplicationWizard section .bot-title').each(function(){
			var $el = $(this);
			$el.html('<div class="text"><p>' + $el.html() + '</p></div>');
			$el.append('<div class="typed"></div>');
		});

		setTimeout( function(){
			var $el = $('#jobApplicationWizard section.current');
			textTyped = botTyped('#jobApplicationWizard section.current .bot-title');
		}, 100);

		var inputs = {};
		var validationErrors = [];

		$('#jobApplicationWizard').steps({
			headerTag: 'h2',
			bodyTag: 'section',
			transitionEffect: 'fade',
			labels: {
				next: "Nästa steg",
				previous: "Tillbaka",
				finish: "Skicka in"
			},

			onInit: function() {
				inputs = {
					job_id: $('#JobId'),
					first_name: $('#Firstname'),
					last_name: $('#Lastname'),
					email: $('#CandidateEmail'),
					phone: $('#CandidatePhone'),
					employments: [
						{
							company: $('#EmploymentCompany1'),
							role: $('#EmploymentRole1'),
							start_date: $('#EmploymentStart1'),
							end_date: $('#EmploymentEnd1'),
							city: $('#EmploymentLocation1'),
							description: $('#EmploymentDescription1')
						},
						{
							company: $('#EmploymentCompany2'),
							role: $('#EmploymentRole2'),
							start_date: $('#EmploymentStart2'),
							end_date: $('#EmploymentEnd2'),
							city: $('#EmploymentLocation2'),
							description: $('#EmploymentDescription2')
						}
					],
					educations: [
						{
							name: $('#EducationName1'),
							school: $('#EducationSchool1'),
							start_date: $('#EducationStart1'),
							city: $('#EducationLocation1')
						}
					],
					letter:  $('#PersonalLetter'),
					approveTerms:  $('#approveTermsCheckbox')
				};
			},

			onStepChanging: function (event, currentIndex, newIndex) {

				for (var i = 0; i < validationErrors.length; i++) {
					validationErrors[i].removeClass('error');
				}

				validationErrors = [];

				if (currentIndex === 0) {
					if (inputs.first_name.val().trim() == '') {
						validationErrors.push(inputs.first_name);
					}
					if (inputs.last_name.val().trim() == '') {
						validationErrors.push(inputs.last_name);
					}
				}

				if (currentIndex === 1) {
					if (inputs.email.val().trim() == '') {
						validationErrors.push(inputs.email);
					}
					if (inputs.phone.val().trim() == '') {
						validationErrors.push(inputs.phone);
					}
				}
				if(currentIndex === 4) {
					console.log(document.getElementById('approveTermsCheckbox').checked)
					if (document.getElementById('approveTermsCheckbox').checked === false) {
						validationErrors.push(inputs.approveTerms);
					}
				}

				if (validationErrors.length) {
					for (var i = 0; i < validationErrors.length; i++) {
						validationErrors[i].addClass('error');
					}

					$('html, body').animate({scrollTop: $('#jobApplicationWizard').offset().top });

					return false;
				}

				if(typeof textTyped != 'undefined'){
					textTyped.destroy();
				}

				return true;
			},
			onFinishing: function(event, currentIndex, newIndex) {
				for (var i = 0; i < validationErrors.length; i++) {
					validationErrors[i].removeClass('error');
				}

				validationErrors = [];
				if(currentIndex === 4) {
					if (document.getElementById('approveTermsCheckbox').checked === false) {
						validationErrors.push(inputs.approveTerms);
					}
				}

				if (validationErrors.length) {
					for (var i = 0; i < validationErrors.length; i++) {
						validationErrors[i].addClass('error');
					}

					$('html, body').animate({scrollTop: $('#jobApplicationWizard').offset().top });

					return false;
				}

				if(typeof textTyped != 'undefined'){
					textTyped.destroy();
				}

				return true;
			},
			onFinished: function(event, currentIndex) {

				var data = {};

				for (var field in inputs) {

					if (inputs[field] instanceof Array) {
						data[field] = [];

						for (var i = 0; i < inputs[field].length; i++) {
							data[field][i] = {};

							var nested = inputs[field][i];

							for (var nestedField in nested) {
								data[field][i][nestedField] = nested[nestedField].val();
							}

						}
						continue;
					}

					data[field] = inputs[field].val().trim();
				}

				var data = {
					type: 'POST', url: '/wp-json/clockwork/v1/mini-cv',
					data: JSON.stringify(data),
					contentType: 'application/json; charset=utf-8',
					dataType: 'json'
				};

				var self = this;

				$(self).find('.last-step').addClass('loading');

				$.ajax(data)
					.done(function() {
						$(self).find('.last-step').hide();
						$(self).find('.actions').hide();
						$(self).find('.finished').show();
						$(self).find('.last-step').removeClass('loading');
						textTyped = botTyped('#jobApplicationWizard .finished .bot-title');
					})
					.fail(function() {

					});
			},

			onStepChanged: function (event, currentIndex, newIndex) {
				$('html, body').animate({scrollTop: $('#jobApplicationWizard').offset().top });
				textTyped = botTyped('#jobApplicationWizard section.current .bot-title');
				return true;
			},
		});
	}
});
