window.$ = require('jquery');
window.jQuery = window.$;
require('swiper');
require('velocity-animate');
require('jquery-steps/build/jquery.steps');

require('./vue/main')();
import approveTerms from './approveterms';
import submitFlexibleContactForm from './flexibleContactForm';
$(function() {
    approveTerms();
    submitFlexibleContactForm();

    $('body').on('click', '.js-facebook-share', function(e){
		e.preventDefault();
		var image = $(this).data('image');
		var desc 	= $(this).data('desc');
		var name 	= $(this).data('name');
		var link 	= $(this).attr('href');
		var caption = $(this).data('caption');

		FB.ui({
			display: "popup",
			method: 'feed',
			name: name,
			link: link,
			description: desc,
			caption: caption,
			picture: image
		});
	});


    $('.js-toggle-similar').click(function(){
    	$('.js-similar').toggleClass('is-active')
    });


    $(document).on('click', '#apply-button', function (event) {
        event.preventDefault();

        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top-69
        }, 500);
    });

    $(document).on('click', '#interest-button', function (event) {
        event.preventDefault();

        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top-69
        }, 500);
    });

    $('.bot-view-joblisting__toolbar__format__button--full').on('click', function(){
        $('.bot-view-joblisting__toolbar__format__button').removeClass('bot-view-joblisting__toolbar__format__button--active');
        $(this).addClass('bot-view-joblisting__toolbar__format__button--active');
        $('.bot-view-joblisting__item__content p').css('display', 'block');
    });

    $('.bot-view-joblisting__toolbar__format__button--less').on('click', function(){
        $('.bot-view-joblisting__toolbar__format__button').removeClass('bot-view-joblisting__toolbar__format__button--active');
        $(this).addClass('bot-view-joblisting__toolbar__format__button--active');
        $('.bot-view-joblisting__item__content p').css('display', 'none');
    });


    /*
    *   site-menu hover
    */
    $('.js-intro-show').hover(function(){
        var num = $(this).data('image');

        $('.area-select__image-wrap__inner[data-image="0"]').removeClass('area-select__image-wrap__inner--visible');

        $('.area-select__image-wrap__inner[data-image='+num+']').addClass('area-select__image-wrap__inner--visible');

    }, function(){
        $('.area-select__image-wrap__inner[data-image="0"]').addClass('area-select__image-wrap__inner--visible');
        $('.area-select__image-wrap__inner[data-image="1"]').removeClass('area-select__image-wrap__inner--visible');
        $('.area-select__image-wrap__inner[data-image="2"]').removeClass('area-select__image-wrap__inner--visible');

        console.log('reset');

    });


    /*
    *   site-menu hover
    */
    $('.menu-main-container .menu > .menu-item').hover(function(){
    	$('.menu-main-container .menu > .menu-item').not(this).addClass('dark');
    }, function(){
    	$('.menu-main-container .menu > .menu-item').not(this).removeClass('dark');
    });


    /*
    *   site-menu hover
    */
    $('.sitemenu__menu__navmenu .menu > .menu-item').hover(function(){
        $('.sitemenu__menu__navmenu .menu > .menu-item').not(this).addClass('dark');
    }, function(){
        $('.sitemenu__menu__navmenu .menu > .menu-item').not(this).removeClass('dark');
    });


    /*
     * Replace all SVG images with inline SVG
     */
     $(document).ready(function() {
         $('img[src$=".svg"]').each(function() {
             var $img = jQuery(this);
             var imgURL = $img.attr('src');
             var attributes = $img.prop("attributes");

             $.get(imgURL, function(data) {
                 // Get the SVG tag, ignore the rest
                 var $svg = jQuery(data).find('svg');

                 // Remove any invalid XML tags
                 $svg = $svg.removeAttr('xmlns:a');

                 // Loop through IMG attributes and apply on SVG
                 $.each(attributes, function() {
                     $svg.attr(this.name, this.value);
                 });

                 // Replace IMG with SVG
                 $img.replaceWith($svg);
             }, 'xml');
         });
     });


    /*
    * Display search button label
    */

    $('.search-button').hover(function(){
        $('.search-label').addClass('search-label--visible');
        }, function(){
        $('.search-label').removeClass('search-label--visible');
    });



    $( window ).resize(function() {
        var winWidth = $(window).width();

        if(winWidth < 800 ){
            var swiper = new Swiper('.article-slide-container', {
                pagination: '.swiper-pagination',
                slidesPerView: '1',
                paginationClickable: true,
                spaceBetween: 30,
                nextButton: '.article-slider__button--next',
                prevButton: '.article-slider__button--prev',
                pagination: '.article-slider__counter',
                paginationType: false,
            });
        } else {
            var swiper = new Swiper('.article-slide-container', {
                pagination: '.swiper-pagination',
                slidesPerView: '2',
                paginationClickable: true,
                spaceBetween: 30,
                nextButton: '.article-slider__button--next',
                prevButton: '.article-slider__button--prev',
                pagination: '.article-slider__counter',
                paginationType: false,
            });
        }
    });

    if($(window).width() < 800){
        var swiper = new Swiper('.article-slide-container', {
            pagination: '.swiper-pagination',
            slidesPerView: '1',
            paginationClickable: true,
            spaceBetween: 30,
            nextButton: '.article-slider__button--next',
            prevButton: '.article-slider__button--prev',
            pagination: '.article-slider__counter',
            paginationType: false,
        });
    } else {
        var swiper = new Swiper('.article-slide-container', {
            pagination: '.swiper-pagination',
            slidesPerView: '2',
            paginationClickable: true,
            spaceBetween: 30,
            nextButton: '.article-slider__button--next',
            prevButton: '.article-slider__button--prev',
            pagination: '.article-slider__counter',
            paginationType: false,
        });
    }


        /*
        * Site menu - Hide when scroll down
        */
        if($(window).width() > 600){
            var lastScrollTop = 0;
            $(window).scroll(function(event){
            var st = $(this).scrollTop();
            if (st > lastScrollTop){
                if(st > 80){
                $('.sitemenu').addClass('sitemenu--minimized');
                $('.search-label').removeClass('search-label--visible');

                if($('.back-to-search').length > 0){
                    $('.back-to-search').addClass('back-to-search--minimized');
                }

                }
            } else {

                $('.sitemenu').removeClass('sitemenu--minimized');

                if($('.back-to-search').length > 0){
                    $('.back-to-search').removeClass('back-to-search--minimized');
                }

            }
            lastScrollTop = st;
            });
        } else {
            var lastScrollTop = 0;
            $(window).scroll(function(event){
            var st = $(this).scrollTop();
            if (st > lastScrollTop){
                if(st > 60){
                $('.sitemenu').addClass('sitemenu--minimized');
                $('.search-label').removeClass('search-label--visible');

                if($('.back-to-search').length > 0){
                    $('.back-to-search').addClass('back-to-search--minimized');
                }

                }
            } else {

                $('.sitemenu').removeClass('sitemenu--minimized');

                if($('.back-to-search').length > 0){
                    $('.back-to-search').removeClass('back-to-search--minimized');
                }

            }
            lastScrollTop = st;
            });
        }


        /*
        * Footer - Scroll top on click
        */
        $(".js-scrolltop").click(function() {
            $("html, body").animate({ scrollTop: 0 }, "slow");
            return false;
        });

        var miniCv = $('#mini-cv');
        var uploadCv = $('#upload-cv');
        var interest = $('#interest');
        var miniCvTab = $('#mini-cv-tab');
        var uploadCvTab = $('#upload-cv-tab');
        var interestTab = $('#interest-tab');

    function toggleMiniCv() {
        miniCv.addClass('job-apply__view-container-active');
        miniCvTab.addClass('job-apply__toggle-button--active');
        uploadCv.removeClass('job-apply__view-container-active');
        uploadCvTab.removeClass('job-apply__toggle-button--active');
        interest.removeClass('job-apply__view-container-active');
        interestTab.removeClass('job-apply__toggle-button--active');
    }

	miniCvTab.click(toggleMiniCv);

    if (window.SECTION == 'cv') {
    	$('html, body').animate({ scrollTop: $('.job-apply').offset().top - 70 });
		toggleCv();
	}

	function toggleCv() {
		miniCv.removeClass('job-apply__view-container-active');
		miniCvTab.removeClass('job-apply__toggle-button--active');
		uploadCv.addClass('job-apply__view-container-active');
		uploadCvTab.addClass('job-apply__toggle-button--active');
		interest.removeClass('job-apply__view-container-active');
		interestTab.removeClass('job-apply__toggle-button--active');
	}

    uploadCvTab.click(toggleCv);

	if ( window.SECTION == 'interest' )
	{
		$('html, body').animate({scrollTop: $('.job-apply').offset().top - 70});
		toggleInterest();
	}

    function toggleInterest() {
        miniCv.removeClass('job-apply__view-container-active');
        miniCvTab.removeClass('job-apply__toggle-button--active');
        uploadCv.removeClass('job-apply__view-container-active');
        uploadCvTab.removeClass('job-apply__toggle-button--active');
        interest.addClass('job-apply__view-container-active');
        interestTab.addClass('job-apply__toggle-button--active');
    }

    interestTab.click(toggleInterest);

    $('#apply-button').click(function() {
		toggleCv();
    });

    $('#interest-button').click(function() {
        toggleInterest();
    });

    function showFilename() {
		var file = $(this)[0].files[0];

		if (file) {
			var display = $(this).siblings('.job-apply__view__content__small-text--file');
			display.html('Vald fil: ' + file.name);
			display.css({display: 'block'});
		}
	}

    $('#Upload_Cv').change(showFilename);
	$('#Upload_PersonalLetter').change(showFilename);
});
