module.exports = function() {
	let Vue = require('vue');
	let VueResource = require('vue-resource');
	let $ = require('jquery');

	Vue.use(VueResource);

	let app = new Vue({
		el: '#vue-main',

		data: {
			coworker_firstname: '',
			coworker_lastname: '',
			coworker_fullname: '',
			coworker_title: '',
			coworker_image: '',
			coworker_image_160: '',
			coworker_image_320: '',
		},

		created: function() {
			this.$http.post('/wp-json/clockwork/v1/coworker/', {
				get: 'coworker' // Dummy data to keep mod_security happy :)
			}).then(response => {
				this.coworker_firstname = response.body.firstname;
				this.coworker_lastname = response.body.lastname;
				this.coworker_fullname = response.body.fullname;
				this.coworker_title = response.body.title;
				this.coworker_image = response.body.image;
				this.coworker_image_160 = response.body.image_160;
				this.coworker_image_320 = response.body.image_320;

				// Ugly hack to allow template to render before the
				// legacy jQuery begins processing it
				setTimeout(function() {
					$('body').trigger('vue-loaded');
				}, 10);
			}, response => {
				console.error('Error loading coworker.');
			});
		}
	});
};