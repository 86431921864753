$(function() {

	$( ".js-open-sitemenu" ).click(function() {
		$('#nav-icon1').toggleClass('open');
		$('.menu-site-overlay').toggleClass('menu-site-overlay--visible');
		$('.sitemenu__menu').toggleClass('sitemenu__menu--visible');
		$('.sitemenu').toggleClass('sitemenu--fixed');	
	});

	$( ".menu-site-overlay" ).click(function() {
		$('#nav-icon1').toggleClass('open');
		$('.menu-site-overlay').toggleClass('menu-site-overlay--visible');
		$('.sitemenu__menu').toggleClass('sitemenu__menu--visible');
		$('.sitemenu').toggleClass('sitemenu--fixed');	
	});

	$(document).keyup(function(e) {
		if (e.keyCode === 27){
			$('#nav-icon1').removeClass('open');
			$('.menu-site-overlay').removeClass('menu-site-overlay--visible');
			$('.sitemenu__menu').removeClass('sitemenu__menu--visible');
			$('.sitemenu').removeClass('sitemenu--fixed');	
		}
	});

});