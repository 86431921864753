/*
// Handle the successful return from the API call
window.onLinkedInProfileSuccess = function (data) {
    console.log(data);
}
// Handle an error response from the API call
window.onLinkedInProfileError = function (error) {
    console.log(error);
}
// Use the API call wrapper to request the member's basic profile data
window.getLinkedInProfileData = function() {
    IN.API.Raw("/people/~").result(window.onLinkedInProfileSuccess).error(window.onLinkedInProfileError);
}
*/


//var LinkedIn = {
//// Handle the successful return from the API call
//    onLinkedInProfileSuccess: function(data) {
//        console.log(data);
//    },
//// Handle an error response from the API call
//    onLinkedInProfileError: function(error) {
//        console.log(error);
//    },
//// Use the API call wrapper to request the member's basic profile data
//    getLinkedInProfileData: function() {
//        IN.API.Raw("/people/~").result(this.onLinkedInProfileSuccess).error(this.onLinkedInProfileError);
//    }
//}