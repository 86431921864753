function approveTerms () {
	// sry..


	const approveInterestCheckbox = document.getElementById('interest_approveTermsCheckbox');
	const approveInterestSubmit = document.getElementById('interest_approveTermsSubmit');

	if(approveInterestCheckbox  && approveInterestSubmit ) {
		if(approveInterestCheckbox === null || approveInterestSubmit === null) {
			return false
		}

		approveInterestCheckbox.checked = false
		approveInterestSubmit.disabled = true

		approveInterestCheckbox.addEventListener('change', () => approveInterestSubmit.disabled = !approveInterestCheckbox.checked)
	}

	const approveUploadCvCheckbox = document.getElementById('uploadcv_approveTermsCheckbox');
	const approveUploadCvSubmit = document.getElementById('uploadcv_approveTermsSubmit');

	if(approveUploadCvCheckbox && approveUploadCvSubmit) {
		if(approveUploadCvCheckbox === null || approveUploadCvSubmit === null) {
			return false
		}
		approveUploadCvCheckbox.checked = false
		approveUploadCvSubmit.disabled = true
		approveUploadCvCheckbox.addEventListener('change', () => approveUploadCvSubmit.disabled = !approveUploadCvCheckbox.checked)
	}


    const flexibleContactForms  = [].slice.call(document.querySelectorAll('.flexibleContactForm'))

    if (flexibleContactForms && flexibleContactForms.length) {
        flexibleContactForms.forEach(function(flexibleContactForm) {

            const checkboxInput = flexibleContactForm.querySelector(
                '.opencontactform_approveTermsCheckbox'
                )

            const submitBtn = flexibleContactForm.querySelector(
                '.opencontactform_approveTermsSubmit'
                )

            checkboxInput.checked = false
            submitBtn.disabled = true
            checkboxInput.addEventListener('change', () => {
                submitBtn.disabled = !checkboxInput.checked
            })
          });
    }
}

export default approveTerms;
